.custom-input-field input:placeholder-shown + label {
  font-size: 16px;
  left: 5px;
  top: 22px;
}

.custom-input-field label,
.custom-input-field input:focus + label,
input:-webkit-autofill + label {
  font-size: 12px;
  font-weight: 500;
  left: 2px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  transition: top 0.4s, left 0.4s, font-size 0.4s;
}

.custom-input-field input::placeholder {
  color: transparent;
}

.custom-input-field input:focus,
.custom-input-field input:not(:placeholder-shown) {
  outline: none;
}
